import React from 'react'
import PropTypes from 'prop-types'

import SvgArrow from '@svg/arrow.inline'
import clsx from 'clsx'

import * as cx from './SpliderButtons.module.scss'

const SpliderButton = ({ prev }) => {
  return (
    <button
      className={clsx(
        'splide__arrow',
        prev ? 'splide__arrow--prev' : 'splide__arrow--next'
      )}
    >
      <span className="sr-only">{prev ? 'Prev' : 'Next'}</span>
      <span>
        <SvgArrow />
      </span>
    </button>
  )
}

const SpliderButtons = ({ wrapperClassName, withText }) => {
  return (
    <div className={clsx('splide__arrows', wrapperClassName)}>
      <SpliderButton prev />

      {withText && (
        <div className="small-heading">
          <strong>Previous &nbsp;|&nbsp; Next</strong>
        </div>
      )}

      <SpliderButton />
    </div>
  )
}

SpliderButtons.propTypes = {
  wrapperClassName: PropTypes.string,
  withText: PropTypes.bool,
}

SpliderButton.propTypes = {
  prev: PropTypes.bool,
}

export default SpliderButtons
