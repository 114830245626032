import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useBlogMetadata } from '@hooks/blog-metadata'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import parse from 'html-react-parser'
import useMedia, { media } from '@hooks/useMedia'

// Element
import ElementImageFile from '@components/global/element/ImageFile'
import { Button } from '@components/global/buttons/Buttons'
import SpliderButtons from '@components/global/buttons/SpliderButtons'

import * as cx from './BlogPostsV4.module.scss'

import backgroundImage from '/content/assets/blog/cards-v4-bg.png'

const BlogPostsV4 = ({
  background = backgroundImage,
  wrapperClassName = '',
  title = 'Trending Posts',
  subtitle = 'Stay updated with our',
  showSubtitle = true,
}) => {
  const [isShowMore, setIsShowMore] = React.useState(false)
  const items = useBlogMetadata().allWpPost.edges
  const isPhone = useMedia(media.phone)

  return (
    <section
      className={`${cx.section} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={cx.heading}>
        {showSubtitle && <p className="subtitle-7">{subtitle}</p>}
        <h5 className="heading-7">{title}</h5>
      </div>

      {!isPhone ? (
        <div className={cx.carousel}>
          <Splide
            hasTrack={false}
            tag="div"
            options={{
              rewind: true,
              autoHeight: true,
              autoWidth: true,
              focus: 0,
              omitEnd: true,
            }}
          >
            <SplideTrack>
              {items.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <div key={index} className={cx.item}>
                      <Link to={item.node.slug} className="image-responsive">
                        <canvas width={456} height={372}></canvas>
                        <ElementImageFile
                          src={modifyImageKeys(item.node?.featuredImage?.node)}
                          alt={item.node.title}
                          lazy={true}
                        />
                      </Link>
                      <div className={cx.content}>
                        <div className={`badge`}>
                          {item.node.primaryCategory?.node?.name
                            ? item.node.primaryCategory?.node?.name
                            : 'Blog'}
                        </div>
                        <p className="subtitle-9">{item.node.title}</p>
                        <p className="default-body large line-clamp-4">
                          {parse(item.node.excerpt)}
                        </p>
                        <Link to={item.node.slug} className="primary-button">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </SplideSlide>
                )
              })}
              <SplideSlide className={cx.dummyItem}></SplideSlide>
            </SplideTrack>
            <SpliderButtons />
          </Splide>
        </div>
      ) : (
        <div className={cx.items}>
          {items.map(
            (item, index) =>
              index < 3 && (
                <div key={index} className={cx.item}>
                  <div className={cx.content}>
                    <Link to={item.node.slug} className="image-responsive">
                      <canvas width={456} height={372}></canvas>
                      <ElementImageFile
                        src={modifyImageKeys(item.node?.featuredImage?.node)}
                        alt={item.node.title}
                        lazy={true}
                      />
                    </Link>
                    <div className={cx.content}>
                      <div className={`badge`}>
                        {item.node.primaryCategory?.node?.name
                          ? item.node.primaryCategory?.node?.name
                          : 'Blog'}
                      </div>
                      <p className="subtitle-9">{item.node.title}</p>
                      <p className="default-body large line-clamp-4">
                        {parse(item.node.excerpt)}
                      </p>
                      <Link to={item.node.slug} className="primary-button">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              )
          )}

          <div className={`overflow-hidden ${isShowMore ? 'h-auto' : 'h-0'}`}>
            {items.map(
              (item, index) =>
                index >= 3 && (
                  <div key={index} className={cx.item}>
                    <div className={cx.content}>
                      <Link to={item.node.slug} className="image-responsive">
                        <canvas width={456} height={372}></canvas>
                        <ElementImageFile
                          src={modifyImageKeys(item.node?.featuredImage?.node)}
                          alt={item.node.title}
                          lazy={true}
                        />
                      </Link>
                      <div className={cx.content}>
                        <div className={`badge`}>
                          {item.node.primaryCategory?.node?.name
                            ? item.node.primaryCategory?.node?.name
                            : 'Blog'}
                        </div>
                        <p className="subtitle-9">{item.node.title}</p>
                        <p className="default-body large line-clamp-4">
                          {parse(item.node.excerpt)}
                        </p>
                        <Link to={item.node.slug} className="primary-button">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          <div className={cx.moreButtonWrapper}>
            {!isShowMore && items.length >= 3 && (
              <Button
                ghost
                text="show more trending posts"
                wrapperClassName={cx.moreButton}
                onClick={() => setIsShowMore(!isShowMore)}
              />
            )}
          </div>
        </div>
      )}
    </section>
  )
}

BlogPostsV4.propTypes = {
  background: PropTypes.string,
  wrapperClassName: PropTypes.string,
  count: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
}

export default BlogPostsV4
