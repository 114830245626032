// extracted by mini-css-extract-plugin
export var carousel = "BlogPostsV4-module--carousel--5ecd4";
export var content = "BlogPostsV4-module--content--cc224";
export var dummyItem = "BlogPostsV4-module--dummy-item--ebaad";
export var heading = "BlogPostsV4-module--heading--26cb2";
export var image = "BlogPostsV4-module--image--a29d9";
export var item = "BlogPostsV4-module--item--15cea";
export var items = "BlogPostsV4-module--items--23a82";
export var moreButton = "BlogPostsV4-module--more-button--5d8ce";
export var moreButtonWrapper = "BlogPostsV4-module--more-button-wrapper--f351b";
export var section = "BlogPostsV4-module--section--2073e";