import React from 'react'
import BlogPostsV1A from '../../components/global/blog/BlogPostsV1A'
import BlogPostsV1B from '../../components/global/blog/BlogPostsV1B'
import BlogPostsV2 from '../../components/global/blog/BlogPostsV2'
import BlogPostsV3 from '../../components/global/blog/BlogPostsV3'
import BlogPostsV4 from '../../components/global/blog/BlogPostsV4'

const BlogCards = () => {
  const [active, setActive] = React.useState('V1A')
  return (
    <section className="flex flex-col justify-center items-center">
      <div className="py-10">
        <div className="flex flex-col items-center">
          <label>Version</label>
          <select
            className="px-6 border border-neutral-600"
            value={active}
            onChange={(e) => {
              setActive(e.target.value)
            }}
          >
            <option value="V1A">V1A</option>
            <option value="V1B">V1B</option>
            <option value="V2">V2</option>
            <option value="V3">V3</option>
            <option value="V4">V4</option>
          </select>
        </div>
      </div>

      {active === 'V1A' && <BlogPostsV1A />}
      {active === 'V1B' && <BlogPostsV1B />}
      {active === 'V2' && <BlogPostsV2 />}
      {active === 'V3' && <BlogPostsV3 />}
      {active === 'V4' && <BlogPostsV4 />}
    </section>
  )
}

export default BlogCards
